<template>

  <div id="cesiumContainer">
      
  </div>

</template>

<script>

    export default {
        data () {
            return {
                viewer :'',
                tileset: '',
            }
        },
        
        mounted (){
            Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MTQzMWRhZS04ZDkyLTRkMmUtYmYyZi1mOGY2ZmRkNDgyZjAiLCJpZCI6MzkwNDIsImlhdCI6MTYwNzA3MjQ1Nn0.s8_tJUR4Y8Rd8spunTJxrh0GrzNbVGYRyXyh4f9eWDs'

            // 创建viewer实例
            this.viewer = new Cesium.Viewer('cesiumContainer', {
                // 需要进行可视化的数据源的集合
                animation: false, // 是否显示动画控件
                shouldAnimate: true,  // 当动画控件出现，用来控制是否通过旋转控件，旋转场景
                homeButton: false, // 是否显示Home按钮
                fullscreenButton: false, // 是否显示全屏按钮
                baseLayerPicker: false, // 是否显示图层选择控件
                navigationInstructionsInitiallyVisible: false,  // 帮助按钮，初始化的时候是否展开
                geocoder: false, // 是否显示地名查找控件
                timeline: false, // 是否显示时间线控件
                sceneModePicker: false, // 是否显示投影方式控件
                navigationHelpButton: false, // 是否显示帮助信息控件
                infoBox: false, // 是否显示点击要素之后显示的信息
                requestRenderMode: true, // 启用请求渲染模式
                scene3DOnly: false, // 每个几何实例将只能以3D渲染以节省GPU内存
                sceneMode: 3, // 初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
                fullscreenElement: document.body, // 全屏时渲染的HTML元素 暂时没发现用处

            })

            window.viewer = this.viewer;

            // 去除版权信息、大气层、天空盒、背景色
            this.viewer._cesiumWidget._creditContainer.style.display = 'none'                  
            // this.viewer.scene.skyAtmosphere.show=false; //关闭大气层     
            // this.viewer.scene.skyBox.show = false;                     
            // this.viewer.scene.backgroundColor = new Cesium.Color(0.0, 0.0, 0.0, 0.0);


            this.initTianditu();
            this.initFlyTo();

            // this.add3DModel();
            // this.add3DTiles();






            


            const handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
            handler.setInputAction(function (click) {
                const pick = self.viewer.scene.pick(click.position);
                if (pick && pick.id) {
                    console.log(pick.id.id + pick.id.name);
                    window.open(pick.id.description);  
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            

            // 添加兴趣点
            this.AddPin("a1", "张信海书", 117.96460958,27.64074986, "http://program.zhanxun.site/wys/WYSG-1/index.html");
            this.AddPin("a2", "梁明房", 117.961928,27.647845, "http://program.zhanxun.site/wys/WYDW-2/index.html");
            this.AddPin("a3", "灵源一派", 117.96487839,27.64069686, "http://program.zhanxun.site/wys/WYSG-3/index.html");
            this.AddPin("a4", "张信海书", 117.97150825,27.64216701, "http://program.zhanxun.site/wys/WYSG-4/index.html");
            this.AddPin("a5", "张信海书", 117.95980882,27.63919393, "http://program.zhanxun.site/wys/WYSG-5/index.html");
            this.AddPin("a6", "张信海书", 117.98109768,27.67884257, "http://program.zhanxun.site/wys/WYSG-6/index.html");
            this.AddPin("a7", "张信海书", 117.94902795,27.63758953, "http://program.zhanxun.site/wys/WYSG-7/index.html");
            this.AddPin("a8", "张信海书", 117.96686981,27.66747905, "http://program.zhanxun.site/wys/WYSG-8/index.html");
            this.AddPin("a9", "张信海书", 117.95071564,27.64385797, "http://program.zhanxun.site/wys/WYSG-9/index.html");
            this.AddPin("a10", "张信海书", 117.94922894,27.64833017, "http://program.zhanxun.site/wys/WYSG-10/index.html");
            this.AddPin("a11", "张信海书", 117.95029152,27.64866204, "http://program.zhanxun.site/wys/WYSG-11/index.html");
            this.AddPin("a12", "张信海书", 117.94847867,27.65620592, "http://program.zhanxun.site/wys/WYSG-12/index.html");
            


        },
        
        methods: {
            
            //加载天地图
            initTianditu() {
                let token = "6e9cebf98fdd9477585ef2a0d3857163";
                // 服务域名
                let tdtUrl = 'https://t{s}.tianditu.gov.cn/';
                // 服务负载子域
                let subdomains=['0','1','2','3','4','5','6','7'];

                let imageryLayers = this.viewer.imageryLayers;
                // 天地图叠加影像服务
                let imgMap = new Cesium.UrlTemplateImageryProvider({
                    url: tdtUrl + 'DataServer?T=img_w&x={x}&y={y}&l={z}&tk=' + token,
                    subdomains: subdomains,
                    tilingScheme : new Cesium.WebMercatorTilingScheme(),
                    maximumLevel : 18
                });
                imageryLayers.addImageryProvider(imgMap);

            },

            // 初始化飞入位置
            initFlyTo() {
 
                // 武夷山风景区
                let initialLon = 117.975691;
                let lat = 27.643063;
                let height = 40000.0;

                // // 将经纬度转换为世界坐标           
                // var target = Cesium.Cartesian3.fromDegrees(initialLon, lat, height);
                // var offset = new Cesium.Cartesian3(-70.06, -68.64, 6.0908)
                // this.viewer.scene.camera.lookAt(target, offset);

                // 飞入
                this.viewer.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(initialLon, lat, height),   
                    orientation: {
                        heading: Cesium.Math.toRadians(0),
                        pitch: Cesium.Math.toRadians(-90),
                        roll: Cesium.Math.toRadians(0)
                    },
                    complete: function callback() {
                        // 定位完成之后的回调函数
                    },
                    duration: 3 // 设置飞行持续时间，默认会根据距离来计算
                });

            },

            
            // 添加三维模型
            add3DModel() {
                
                var modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(
                    Cesium.Cartesian3.fromDegrees(117.97460958, 27.64074986, 0.0)
                );

                this.viewer.scene.primitives.add(Cesium.Model.fromGltf({
                    url: '../data/model/GroundVehicle.glb',
                    modelMatrix: modelMatrix,
                    scale: 100.0
                }));
            },

            // // 添加三维建筑
            // add3DTiles() {

            //     // 3d建筑物        
            //     var tileset = new Cesium.Cesium3DTileset({
            //         url:"../data/zjgy/tileset.json",
            //     });
                
            //     this.viewer.scene.primitives.add(tileset);
            //     this.viewer.zoomTo(tileset, new Cesium.HeadingPitchRange(0.0, -0.3, 0.0));
            // },

            // 添加兴趣点
            AddPin(id, name, lat, lng, desc){
                    
                // var pinBuilder = new Cesium.PinBuilder();

                // var url = "@/assets/images/circle.png";
                // // var url = "require('./../assets/images/circle.png')";

                // var groceryPin = Cesium.when(
                //     pinBuilder.fromUrl(url, Cesium.Color.GREEN, 48),
                //     function (canvas) {
                //         return this.viewer.entities.add({
                //             id: id,
                //             name: name,
                //             description: desc,
                //             position: Cesium.Cartesian3.fromDegrees(lat, lng),
                //             billboard: {
                //                 image: canvas.toDataURL(),
                //             },
                //         });
                //     }
                // );


                this.viewer.entities.add({
                    id: id,
                    name: name,
                    description: desc,
                    position: Cesium.Cartesian3.fromDegrees(lat, lng),
                    billboard: {                    
                        image: "./images/circle.png",
                        width: 32,
                        height: 32
                    }
                });

            }
        }

        

    }



</script>


<style>

#cesiumContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;

    background-color: blue;
    
}




</style>