import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'element-plus/lib/theme-chalk/index.css'
import ElementPlus from 'element-plus'

import VideoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'


createApp(App).use(ElementPlus).use(VideoPlayer).use(store).use(router).mount('#app')
