<template>
    <div id="index" ref="appRef">

        <div class="header">
            <div class="title_cn_bd">
                <span class="title_cn_word">福州市晋安湖三创园</span>
            </div>
            <div class="title_en_bd">
                <span class="title_en_word">
                    FU ZHOU SHI JIN AN HU SAN CHUANG YUAN
                </span>
            </div>
            <div class="headerleft"></div>
            <div class="headerright"></div>
        </div>

        <router-view id='router' />

        <div class="footer">
            <ul>

                <li>
                    <router-link to="/qwys" class="normal" active-class="current">
                        <p class="route_cn">园区优势</p>
                        <p class="route_en">PARK ADVANTAGE</p>
                    </router-link>
                </li>
                <li>
                    <router-link to="/yqgh" class="normal" active-class="current">
                        <p class="route_cn">园区规划</p>
                        <p class="route_en">PARK PLANNING</p>
                    </router-link>
                </li>
                <li>
                    <router-link to="/cyts" class="normal" active-class="current">
                        <p class="route_cn">产业特色</p>
                        <p class="route_en">INDUSTRY CHARACTERISTICS</p>
                    </router-link>
                </li>
                <!-- <li>
                    <router-link to="/ptgc" class="normal" active-class="current">
                        <p class="route_cn">配套工程</p>
                        <p class="route_en">SUPPORTING PROJECT</p>
                    </router-link>
                </li> -->
                <li>
                    <router-link to="/ztdt" class="normal" active-class="current">
                        <p class="route_cn">专题地图</p>
                        <p class="route_en">THEMATIC MAP</p>
                    </router-link>
                </li>
                <li>
                    <!-- <router-link to="/kjcx" class="normal" active-class="current"> -->
                    <router-link to="/#" class="normal" active-class="current">
                        <p class="route_cn">科技创新</p>
                        <p class="route_en">TECHNOLOGICAL INNOVATION</p>
                    </router-link>
                </li>
                <!-- <li> 
                    <router-link to="/yqdl" class="normal" active-class="current">
                    <p class="route_cn">园区导览</p> 
                    <p class="route_en">PREFERENTIAL POLICY</p>
                    </router-link> 
                </li> -->
            </ul>
        </div>

    </div>
</template>



<script>
import drawMixin from "@/utils/drawMixin/drawMixin";

export default {
    mixins: [drawMixin],

    mounted() {
        console.log("[smartpt start]");
    },

}

</script>



<style lang="scss">
@import "~@/utils/drawMixin/scss/index.scss";

* {
    margin: 0;
    padding: 0px;
    letter-spacing: 1px; // 字间距
}

/*去掉列表前面的小点*/
li {
    list-style: none;
}

/*去掉下划线*/
a {
    text-decoration: none;
}

html,
body,
#app {
    margin: 0;
    width: 100%;
    height: 100%;
    // width: 3840px;
    // height: 944px;
    // overflow: hidden;
}

#index {
    text-align: center;
    position: absolute;
    background: url("~@/assets/images/main/mainbg.png") no-repeat;
}

#router {
    z-index: -10;
}

.header {
    position: fixed;
    z-index: 100;
    width: 3840px;
    height: 88px;
    left: 50%;
    transform: translate(-50%, 0);
    background: url("~@/assets/images/main/mainheaderbg.png") no-repeat;
}

.title_cn_bd {
    width: 600px;
    height: 46px;
    top: 15px;
    position: absolute;
    margin: 0 0 0 1620px;
}
.title_cn_word {
    font-size: 30px;
    font-weight: 500;
    font-family: "Microsoft YaHei";
    line-height: 46px;
    text-align: center;
    letter-spacing: 5px;
    color: rgba(241, 241, 241, 1);
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.22);
}

.title_en_bd {
    width: 540px;
    height: 11px;
    top: 57px;
    position: absolute;
    margin: 0 0 0 1650px;
}
.title_en_word {
    font-size: 8px;
    line-height: 11px;
    text-align: center;
    color: rgba(241, 241, 241, 0.3);
}

.headerleft {
    width: 356px;
    height: 14px;
    top: 66px;
    left: 1187px;
    position: absolute;
    background: url("~@/assets/images/main/mainheaderleft.png") no-repeat;
}
.headerright {
    width: 356px;
    height: 14px;
    top: 66px;
    left: 2297px;
    position: absolute;
    background: url("~@/assets/images/main/mainheaderright.png") no-repeat;
}

.footer {
    bottom: 50px;
    left: 53.8%;
    width: 1120px;
    height: 50px;
    text-align: center;
    position: absolute;
    margin: 0 0 0 -660px;
    z-index: 20;
    /* opacity: 0.3; */
}

.normal {
    width: 190px;
    height: 77px;
    float: left;
    text-align: center;
    margin: 0 16px 0 16px;
    background: url("~@/assets/images/main/footer_li.png") no-repeat;
    pointer-events: auto;
    cursor: pointer;

    padding: 6px 0;
    /* font-family: "SourceHanSansCN"; */
    // color: rgb(0, 168, 164);
}

.current {
    background: url("~@/assets/images/main/footer_li_current.png") no-repeat;
    // color: rgb(31, 187, 227);
}

.route_cn {
    margin: 18px 0 0 0;
    color: rgb(255, 255, 255, 0.9);
    font-size: 18px;
    line-height: 16px;
}

.route_en {
    margin: 15px 0 0 0;
    color: rgb(255, 255, 255, 0.6);
    font-size: 12px;
    line-height: 0px;
    transform: scale(0.6);
}

// 小模块样式
.module {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: #0f2539;
    overflow: hidden;
}

.module .caption {
    top: 13px;
    left: 10px;
    width: 300px;
    height: 34px;
    text-align: center;
    position: relative;
    background: url("~@/assets/images/main/imgcaption.png") no-repeat left;
}
.module .caption p {
    left: 32px;
    color: rgb(255, 255, 255, 1);
    font-size: 18px;
    line-height: 34px;
    text-align: left;
    position: relative;
}

.module .caption2 {
    top: 8px;
    left: 5px;
    width: 300px;
    height: 24px;
    text-align: center;
    position: relative;
    background: url("~@/assets/images/main/imgcaption2.png") no-repeat left;
}
.module .caption2 p {
    left: 23px;
    color: rgb(255, 255, 255, 1);
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    position: relative;
}

// 地图的括号蒙板
.mapleft {
    z-index: 10;
    top: 0px;
    left: 0px;
    width: 520px;
    height: 945px;
    pointer-events: none;
    position: absolute;
    background: url("~@/assets/images/main/mapleft.png") no-repeat;
}
.maptop {
    z-index: 10;
    top: 0px;
    left: 0px;
    width: 3840px;
    height: 240px;
    pointer-events: none;
    position: absolute;
    background: url("~@/assets/images/main/maptop.png") center center no-repeat;
    background-size: 100% 100%;
}
.mapright {
    z-index: 10;
    top: 0px;
    right: 0px;
    width: 520px;
    height: 945px;
    pointer-events: none;
    position: absolute;
    background: url("~@/assets/images/main/mapright.png") no-repeat;
}
.mapbottom {
    z-index: 10;
    bottom: 0px;
    left: 0px;
    width: 3840px;
    height: 240px;
    pointer-events: none;
    position: absolute;
    background: url("~@/assets/images/main/mapbottom.png") center center
        no-repeat;
    background-size: 100% 100%;
}

// cesium框
.map {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
}
#cesiumContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: black !important;
}
</style>
