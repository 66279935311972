import { createStore } from 'vuex'

export default createStore({
  state: {
    objWeather: {},             // 天气数据

    gis_setting_dem: "1111",        // 高程设置
    gis_setting_3dtiles: "",    // 三维建筑设置
    gis_setting_tiltphoto: "",  // 倾斜摄影设置

  },

  mutations: {
    setWeather(state, value) {
      state.objWeather = value
    },

    setGisSettingDem(state, value) {
      state.gis_setting_dem = value
    },

    setGisSetting3Dtiles(state, value) {
      state.gis_setting_3dtiles = value
    },

    setGisSettingTiltphoto(state, value) {
      state.gis_setting_tiltphoto = value
    },

  },

  getter: {
    getWeater: state => state.objWeather,
    getGisSettingDem: state => state.gis_setting_dem,
    getGisSetting3Dtiles: state => state.gis_setting_3dtiles,
    getGisSettingTiltphoto: state => state.gis_setting_tiltphoto,

  },

  actions: {
  },

  modules: {
  }

})
