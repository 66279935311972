import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'


const QWYS = ()=> import('../views/qwys');
const YQGH = ()=> import('../views/yqgh');
const CYTS = ()=> import('../views/cyts');
const PTGC = ()=> import('../views/ptgc');
const KJCX = ()=> import('../views/kjcx');
const YQDL = ()=> import('../views/yqdl');
const ZTDT = ()=> import('../views/ztdt');


const routes = [
  {
    path: '/',
    name: 'Home',
    // component: Home
    
    redirect: "/qwys",
    component: QWYS
  },


  {
    path: '/qwys',
    name: '区位优势',
    component: QWYS
  },
  {
    path: '/yqgh',
    name: '园区规划',
    component: YQGH
  },
  {
    path: '/cyts',
    name: '产业特色',
    component: CYTS
  },
  {
    path: '/ptgc',
    name: '配套工程',
    component: PTGC
  },
  {
    path: '/kjcx',
    name: '科技创新',
    component: KJCX
  },
  {
    path: '/ztdt',
    name: '专题地图',
    component: ZTDT
  },
  {
    path: '/yqdl',
    name: '园区导览',
    component: YQDL
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
